import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import HomeImage from '../components/Images/HomeImage';


const InseparablesPage = () => (
  <Layout>
    <SEO title="Les inséparables" />

    <div className="home-2-images">
      <div>
        <HomeImage image="braceletProfil" />
      </div>
      <div>
        <HomeImage image="braceletProfilFerme" />
      </div>
    </div>


    <div style={{ backgroundColor: '#6FA287', color: 'white' }}>
      <div className="content">
        <p className="quote">
          « Toi et moi, partageons le même bijou, il nous est singulier et fait de
          nous <i>des inséparables</i>. »
        </p>
      </div>
    </div>

    <div className="content page">
      <h1>Les inséparables</h1>
      <div className="home-paragraph">
        <div>
          <p>
          Le bracelet <i>Les inséparables</i> s’inspire, d’un premier bijou, de la fine
          gourmette de naissance portée par les tous jeunes enfants.
          Une plaque, une chaîne, une identité… celle qu’on a depuis toujours,
          celle qu’on a créée au fil des années. La nôtre, unique.
          Lié(e), agrandit la plaque en un demi-jonc plat et l’accompagne d’une
          chaîne qui se double et vient s’accrocher délicatement au bord du jonc.
          L’association du métal précieux, robuste, pur et lisse, aux fines mailles
          souples et légères, exprime le mariage de deux designs, deux entités,
          deux êtres…<br /><br />
          Le modèle se décline dans les trois coloris d’or 18 carats (blanc, jaune et
          rose). Il existe également une version en argent doré et en argent.
          </p>
        </div>
      </div>
    </div>

    <div className="home-2-images" style={{ backgroundColor: '#fee5e4'}}>
      <div>
        <HomeImage image="dessin" />
      </div>
    </div>

    <div className="content">
      <div className="home-paragraph">
        <h2>Le design</h2>
        <p>
            Le design intemporel du bracelet a été pensé pour être
            transgénérationnel.<br /><br />
            Il peut être porté par homme, femme et enfant. Sa chaîne lui permet
            d’être évolutif et de s’agrandir avec l’enfant. Son élégance unisexe
            l’inscrit dans une tendance de bijou non genré, classique et symbolique.
            Il mélange savoir-faire, simplicité et modernité pour que chacun puisse
            facilement l’adopter et le mixer aux tendances de la saison.<br/><br/>
            La gravure délicate, unique à la fois contemporaine et poétique se fait discrète en bas et a droite du bijoux comme une signature de tableaux, comme une intime dédicace …
        </p>
      </div>
    </div>


    <div className="home-2-images">
      <div>
        <HomeImage image="ambiance" />
      </div>
      <div>
        <HomeImage image="ambiance2" />
      </div>
    </div>
  </Layout>
);

export default InseparablesPage;
